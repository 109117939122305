import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "@styles/landing.css"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import Section from "@components/section"
import Seo from "@components/seo"
import References from "@components/references"
import { useWindowSize } from "@components/utils"

// Assets
import logoOutline from "@images/home-page/logo-outline-landing.png"
import purpleCircleArrow from "@images/icons/icon-purple-circle-arrow.svg"
import blackArrowCircleArrow from "@images/icons/icon-black-arrow-circle.svg"
import xywavLogoBG from "@images/home-page/xywav-logo-landing.svg"

const IndexPage = ({ location, data }) => {
  const [narcHover, setNarcHover] = useState(false)
  const [ihHover, setIhHover] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const narcHoverParam = searchParams.get("narcHover")
    const ihHoverParam = searchParams.get("ihHover")

    if (parseInt(narcHoverParam) === 1) setNarcHover(true)
    if (parseInt(ihHoverParam) === 1) setIhHover(true)
  }, [])

  const menuBoxItem = tw`bg-white bg-opacity-75 w-full min-h-[165px] lg:(w-[570px] h-[191px])`
  const blackArrowStyle = tw`absolute w-[50px] h-[50px] lg:(w-[70px] h-[70px] right-10)`
  const purpleArrowStyle = tw`absolute  w-[50px] h-[50px] lg:(w-[70px] h-[70px] right-10)`
  const blackArrowTransition = tw`right-5 opacity-0 transition-all duration-200 lg:(group-hover:(opacity-100 right-[150px]))`
  const purpleArrowTransition = tw`right-5 opacity-100 transition-all duration-200  lg:(group-hover:(opacity-0 right-[150px]))`
  const blackArrowHover = tw`lg:(opacity-100 right-[150px])`
  const purpleArrowHover = tw`lg:(opacity-0 right-[150px])`
  const imageStyle = tw`hidden transition-opacity duration-500 delay-75 lg:(opacity-0 absolute right-0 block w-[170px]) h-[186px] group-hover:opacity-100`
  const imageHover = tw`lg:opacity-100`

  const TransitionImageComponent = ({ theme, narcHover, ihHover }) => {
    const image =
      theme === "narcolepsy"
        ? getImage(data.narcoTransitionImage)
        : getImage(data.ihTransitionImage)
    const altText =
      theme === "narcolepsy"
        ? "A doctor talking to his potential XYWAV patient."
        : "A woman putting on her shoes to start her day after a successful night’s sleep."

    return (
      <GatsbyImage
        image={image}
        alt={altText}
        css={[
          imageStyle,
          narcHover && theme === "narcolepsy" && imageHover,
          ihHover && theme === "ih" && imageHover,
        ]}
      />
    )
  }

  const { width } = useWindowSize()

  const DateCircle = ({ month, year }) => {
    return (
      <div tw="z-10 absolute left-[20px] flex justify-center items-center landing-gradient3-bg text-center rounded-full w-[74px] h-[74px] lg:(left-[120px] top-[-20px] w-[110px] h-[110px])">
        <div tw="text-center text-white">
          <p tw="text-center text-base uppercase font-extrabold leading-[21px] lg:(text-2xl leading-[18px])">
            {month || null}
          </p>
          <p tw="text-center text-[20px] leading-[21px] font-extrabold lg:(text-[30px] leading-[28px])">
            {year || null}
          </p>
        </div>
      </div>
    )
  }

  const ArrowAndImage = ({ theme }) => {
    return (
      <>
        <TransitionImageComponent
          theme={theme}
          narcHover={narcHover}
          ihHover={ihHover}
        />
        <img
          css={[
            purpleArrowStyle,
            purpleArrowTransition,
            narcHover && theme === "narcolepsy" && purpleArrowHover,
            ihHover && theme === "ih" && purpleArrowHover,
          ]}
          src={purpleCircleArrow}
          alt=""
        />
        <img
          css={[
            blackArrowStyle,
            blackArrowTransition,
            narcHover && theme === "narcolepsy" && blackArrowHover,
            ihHover && theme === "ih" && blackArrowHover,
          ]}
          src={blackArrowCircleArrow}
          alt=""
        />
      </>
    )
  }

  const LandingMenuItems = () => {
    return (
      <div tw="mt-5 lg:(flex mt-16) relative">
        <Link
          to="/narcolepsy/"
          className="group"
          css={[menuBoxItem, tw`flex items-center relative`]}
        >
          <div tw="ml-4 w-[70%] lg:(ml-8 w-[300px])">
            <p tw="text-2xl font-semibold leading-[24px] lg:(text-[30px] leading-[34px] font-bold)">
              Narcolepsy
            </p>
            <p tw="font-medium pt-4">
              XYWAV for the treatment of cataplexy and/or excessive daytime
              sleepiness (EDS) in narcolepsy.<sup>1</sup>
            </p>
          </div>
          <ArrowAndImage theme="narcolepsy" />
          <div tw="tableBGNarco absolute w-full h-[5px] top-0 left-0"></div>
        </Link>
        <Link
          to="/idiopathic-hypersomnia/"
          className="group"
          css={[
            menuBoxItem,
            tw`flex items-center relative min-h-[195px] 2sm:min-h-[165px] lg:(ml-[30px])`,
          ]}
        >
          <div tw="ml-4 w-[70%] lg:(ml-8 w-[300px])">
            <p tw="text-2xl font-semibold leading-[24px] lg:(text-[30px] leading-[34px] font-bold)">
              Idiopathic Hypersomnia
            </p>
            <p tw="font-medium pt-4">
              XYWAV—the only FDA&#8209;approved treatment for adults with
              idiopathic hypersomnia<sup>1,2</sup>
            </p>
          </div>
          <ArrowAndImage theme="ih" />
          <div tw="tableBGIh absolute w-full h-[5px] top-0 left-0"></div>
        </Link>
      </div>
    )
  }

  const referenceData = [
    <>
      XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
      oxybates). Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals,
      Inc.
    </>,
    <>
      FDA grants first of its kind indication for chronic sleep disorder
      treatment. News release. U.S. Food and Drug Administration; August 12,
      2021. Accessed June 17, 2024.
      https://www.fda.gov/news-events/press-announcements/fda-grants-first-its-kind-indication-chronic-sleep-disorder-treatment
    </>,
    <>Data on File (XYW-2022-022). Palo Alto, CA: Jazz Pharmaceuticals, Inc.</>,
    <>
      US Department of Health and Human Services, Food and Drug Administration.
      Search orphan drug designations and approvals: Xywav. Accessed June 17,
      2024. https://www.accessdata.fda.gov/scripts/
      <span tw="break-all lg:break-normal ">
        opdlisting/oopd/detailedIndex.cfm?cfgridkey=694619
      </span>
    </>,
    <>
      US Department of Health and Human Services, US Food and Drug
      Administration website. Clinical superiority findings. Accessed June 17,
      2024. https://www.fda.gov/industry/
      <span tw="break-all lg:break-normal ">
        designating-orphan-product-drugs-and-biological-products/clinical-superiority-findings
      </span>
    </>,
    <>
      Chen C, Jenkins J, Zomorodi K, Skowronski R. Pharmacokinetics,
      bioavailability, and bioequivalence of lower-sodium oxybate in healthy
      participants in two open-label, randomized, crossover studies.{" "}
      <span tw="italic">Clin Transl Sci</span>. 2021;14(6):2278-2287.
    </>,
    <>
      Xyrem<sup>&reg;</sup> International Study Group. Further evidence
      supporting the use of sodium oxybate for the treatment of cataplexy: a
      double-blind, placebo-controlled study in 228 patients.{" "}
      <span tw="italic">Sleep Med</span>. 2005;6(5):415-421.
    </>,
    <>
      XYREM<sup>&reg;</sup> (sodium oxybate). Prescribing Information. Palo
      Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
  ]

  useEffect(() => {
    const targets = document.querySelectorAll(".scroll-element")

    const options = {
      rootMargin: width <= 750 ? "300px 0px -50px 0px" : "0px 0px -100px 0px",
      threshold: 1.0,
      triggerOnce: true,
    }

    const observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fadeIn")
        }
      })
    }, options)

    targets.forEach(function (target) {
      target.classList.add("opacity-0")
      observer.observe(target)
    })
  }, [])

  return (
    <Layout
      location={location}
      lowerVA={true}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="XYWAV Official Healthcare Provider (HCP) Site | XYWAV"
        description="Understand how XYWAV® (calcium, magnesium, potassium, and sodium oxybates) can help with your patient’s narcolepsy or idiopathic hypersomnia. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse. "
        location={location}
      />

      <Section
        addedStyles={tw`overflow-hidden landing-hero-bg min-h-[470px] lg:(min-h-[560px])`}
      >
        <img
          src={logoOutline}
          alt=""
          tw="absolute hidden w-[1413px] md:(block top-[100px] right-[-100px])"
        />
        <Container>
          <h1 tw="gradient2 bg-clip-text text-transparent font-bold text-[28px] leading-[28px] md:(max-w-[450px]) lg:(text-[48px] leading-[53px])">
            Discover XYWAV
          </h1>
          <p tw="text-[20px] leading-[22px] py-2 lg:(text-[30px] leading-[34px] max-w-[500px])">
            Which sleep disorder are you looking to explore?
          </p>

          {<LandingMenuItems />}
        </Container>
      </Section>
      <Section>
        <Container addedStyles={tw`flex justify-center text-center`}>
          <p>
            <strong>
              XYWAV is the first and only{" "}
              <span tw="whitespace-nowrap">FDA-approved</span> treatment for
              both narcolepsy and idiopathic{" "}
              <span tw="whitespace-nowrap">
                hypersomnia<sup>1,2</sup>
              </span>
            </strong>
          </p>
        </Container>
      </Section>
      <Section addedStyles={tw`landing-gradient1-bg`}>
        <Container addedStyles={tw`flex justify-center text-center`}>
          <p>
            XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
            oxybates) oral solution, 0.5 g/mL total salts (equivalent to 0.413
            g/mL of oxybate) is indicated for the treatment of cataplexy or
            excessive daytime sleepiness (EDS) in patients 7 years of age and
            older with narcolepsy, and for the treatment of idiopathic
            hypersomnia (IH) in adults.
          </p>
        </Container>
      </Section>
      <Section>
        <Container addedStyles={tw`sm:(w-full) flex justify-center`}>
          <img
            src={xywavLogoBG}
            tw="hidden lg:(absolute block right-[-300px] bottom-[-90px])"
            alt=""
          />
          <div tw="w-full relative block rounded-[40px] landing-gradient2-bg before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[2px] bg-white rounded-[37px]) md:(w-[90%])">
            <div tw="relative z-10 flex justify-center items-center rounded-t-[40px] w-full min-h-[140px] text-white text-center landing-gradient2-bg">
              <h2 tw="font-normal text-2xl px-6 leading-[130%] lg:(text-4xl leading-[29px])">
                Jazz Pharmaceuticals continues to lead the way
                <br />
                <span tw="font-semibold text-[30px] leading-[37px]">
                  with XYWAV
                </span>
              </h2>
            </div>

            <div tw="relative flex flex-col z-10">
              <div
                className="scroll-element"
                tw="relative z-10 opacity-0 transition-all duration-200 mt-[40px] pl-[120px] pr-[15px] mb-[20px] lg:(mt-[80px] pl-[260px] pr-[100px] mb-[60px])"
              >
                <DateCircle month="dec" year="2021" />
                <p tw="font-bold">
                  XYWAV granted Orphan Drug Exclusivity for the treatment of
                  idiopathic hypersomnia in adult patients
                </p>
                <p tw="py-2">
                  The second Orphan Drug Exclusivity for the medication
                  <sup>3,4</sup>
                </p>
              </div>
              <div
                className="scroll-element"
                tw="relative z-10 opacity-0 transition-all duration-200 pl-[120px] pr-[15px] mb-[20px] lg:(pl-[260px] pr-[100px] mb-[60px])"
              >
                <DateCircle month="aug" year="2021" />
                <p tw="font-bold">
                  XYWAV indication expanded to idiopathic hypersomnia
                </p>
                <p tw="py-2">
                  The first and only FDA&#8209;approved treatment for idiopathic
                  hypersomnia in adult patients<sup>1,2</sup>
                </p>
              </div>
              <div
                className="scroll-element"
                tw="relative z-10 opacity-0 transition-all duration-200 pl-[120px] pr-[15px] mb-[20px] lg:(pl-[260px] pr-[100px] mb-[70px])"
              >
                <DateCircle month="jun" year="2021" />
                <p tw="font-bold">
                  XYWAV granted Orphan Drug Exclusivity for the treatment of
                  narcolepsy
                </p>
                <p tw="py-2">
                  According to FDA, XYWAV is clinically superior to XYREM
                  <sup>&reg; </sup>
                  (sodium oxybate) oral solution due to greater cardiovascular
                  (CV) safety because the reduction in sodium at recommended
                  doses will help a substantial portion of indicated patients
                  reduce the risk of developing CV disease<sup>5*</sup>
                </p>
                <p tw="relative text-[12px] leading-[15px] text-indent[-4px] pl-[5px]">
                  <sup>*</sup>Based on a determination of Orphan Drug
                  Exclusivity by the FDA Office of Orphan Products Development
                  (OOPD) between XYWAV and XYREM. There are no head-to-head data
                  for XYWAV and XYREM.
                </p>
              </div>
              <div
                className="scroll-element"
                tw="relative z-10 opacity-0 transition-all duration-200 pl-[120px] pr-[15px] mb-[40px] lg:(pl-[260px] pr-[100px] mb-[70px])"
              >
                <DateCircle month="jul" year="2020" />
                <p tw="font-bold">
                  XYWAV initially{" "}
                  <span tw="whitespace-nowrap">FDA-approved</span> for the
                  treatment of cataplexy and/or EDS
                </p>
                <p tw="py-2">
                  The first and only{" "}
                  <span tw="whitespace-nowrap">
                    low-sodium<sup>&dagger;</sup>
                  </span>{" "}
                  oxybate for the treatment of cataplexy and/or EDS in patients
                  with narcolepsy 7 years of age and older<sup>1,6</sup>
                </p>
                <ul>
                  <li tw="text-base relative mt-0 ml-5 before:(content['&#8226;'] text-[16px] absolute left-[-15px] top-[-3px])">
                    XYREM, a high-sodium oxybate, was first approved as a
                    treatment for cataplexy in adult patients with narcolepsy in
                    2002
                    <sup>7,8</sup>
                  </li>
                </ul>
                <p tw="relative text-[12px] leading-[15px] text-indent[-4px] pl-[5px] pt-2 left-[-102px] w-[132%] 2sm:w-[125%] lg:(left-0 w-full)">
                  <sup>&dagger;</sup>XYWAV contains 131 mg of sodium at the
                  maximum recommended 9&#8209;g nightly&nbsp;dose.
                  <sup>6</sup>
                </p>
              </div>
              <div tw="h-[67%] absolute w-[1px] bg-dusty-gray-1 left-[57px] top-[80px] md:h-[63%] lg:(h-[70%] left-[174px] top-[100px])"></div>
            </div>
          </div>
        </Container>
      </Section>
      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const imageQuery = graphql`
  query ImageQuery {
    narcoTransitionImage: file(
      relativePath: { eq: "home-page/landing-narco.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 340, placeholder: NONE)
      }
    }
    ihTransitionImage: file(relativePath: { eq: "home-page/landing-ih.png" }) {
      childImageSharp {
        gatsbyImageData(width: 340, placeholder: NONE)
      }
    }
  }
`
